import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import {observer} from "mobx-react";
import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";

export const TestComp: React.FC = observer(() => {
    const [response, setResponse] = useState({});
    const {getAccessTokenSilently} = useAuth0();
    useEffect(() => {
    }, [])
    return (<>
        <Button
            onClick={async () => {
                console.log(`API: ${process.env.REACT_APP_API_URL}`)
                console.log(`Origin: ${window.location.origin}`)
                getAccessTokenSilently({
                    detailedResponse: true,
                }).then((token) => {
                    axios.get(process.env.REACT_APP_API_URL + "/api/user", {
                        headers: {
                            'Authorization': `Bearer ${token.access_token}`,
                            'Access-Control-Allow-Origin': '*',
                        },
                    }).then(resp => {
                        console.log(resp)
                        setResponse(resp.data);
                    }).catch(err => {
                        console.log(err)
                    });
                })
            }}
        >
            Userinfo
        </Button>
        <>
            <pre>{JSON.stringify(response, null, 2)}</pre>
        </>
    </>);
})