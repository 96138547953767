import React from "react";
import {AppBar, Avatar, Button, Chip, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {observer} from "mobx-react";
import {styled} from '@mui/system'
import {useAuth0} from "@auth0/auth0-react";


const StyledTitle = styled(Typography)({
    flexGrow: 1,
});

export const YuAppBar: React.FC = observer(() => {
    const {
        user,
        loginWithRedirect,
        logout,
    } = useAuth0();

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin + "/auth/logout",
            }
        });

    return <AppBar position='static'>
        <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
                <MenuIcon/>
            </IconButton>
            <StyledTitle variant="h6">
                Yu
            </StyledTitle>
            {
                user ?
                    <>
                        <Chip
                            avatar={<Avatar alt="" src={user.picture}/>}
                            label={user.name}
                            variant="filled"
                        />
                        <Button
                            color="inherit"
                            onClick={logoutWithRedirect}
                        >
                            Logout
                        </Button>
                    </>
                    :
                    <Button
                        color="inherit"
                        onClick={() => loginWithRedirect()}
                    >
                        Login
                    </Button>
            }
        </Toolbar>
    </AppBar>
});