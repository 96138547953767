import React from 'react';
import {observer} from "mobx-react";
import {YuAppBar} from "./YuAppBar";
import {BrowserRouter} from "react-router-dom";
import ViewPort from "./ViewPort";
import {useAuth0} from "@auth0/auth0-react";
import {Typography} from "@mui/material";

export const App: React.FC = observer(() => {
    const {isLoading} = useAuth0()
    return <BrowserRouter>
        {
            isLoading ?
                <Typography>Loading</Typography>
                :
                <>
                    <YuAppBar/>
                    <ViewPort/>
                </>
        }
    </BrowserRouter>
});

