import React from 'react';
import * as serviceWorker from './serviceWorker';
import {ThemeProvider} from "@mui/material/styles";
import {CssBaseline} from "@mui/material";
import {createRoot} from "react-dom/client";
import {red} from '@mui/material/colors';
import {createTheme} from "@mui/material/styles";
import {App} from "./App";
import {Auth0Provider, Auth0ProviderOptions} from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import { getConfig } from "./config";


const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const history = createBrowserHistory();

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
    },
});


const onRedirectCallback = (appState: any) => {
    history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig : Auth0ProviderOptions = {
    domain: config.domain,
    clientId: config.clientId,
    onRedirectCallback,
    cacheLocation: 'localstorage',
    useRefreshTokens: false,
    authorizationParams: {
        scope: "email profile upload:image",
        redirect_uri: window.location.origin,
        ...(config.audience ? { audience: config.audience } : null),
    },
};

root.render(
    <Auth0Provider
        {...providerConfig}
    >
    <ThemeProvider theme={theme}>
        <iframe hidden title="session tracking" src={process.env.REACT_APP_API_URL + "/api/track"}></iframe>
        <CssBaseline/>
        <App/>
    </ThemeProvider>
    </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
