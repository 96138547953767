import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Typography} from "@mui/material";

export const Logout: React.FC = observer(() => {
    useEffect(() => {
        console.log("useEffect called");
        let timeout = setTimeout(()=>{
            console.log("Redirecting...");
            window.location.replace("/");
        }, 3000);
        return ()=> {
            console.log("Clearing timeout");
            clearTimeout(timeout);
        }
    }, []);
    return <Typography>
        You have been logged out.
    </Typography>
});