import React from "react";
import {Route, Routes} from "react-router-dom";
import {TestComp} from "./TestComp";
import {observer} from "mobx-react";
import {Logout} from "./Logout";
import {useAuth0} from "@auth0/auth0-react";
import {Typography} from "@mui/material";

const ViewPort: React.FC = observer(() => {
    const {user} = useAuth0();

    return <>
        {
            user ?
                <Routes>
                    <Route path="/validation" element={<TestComp/>}/>
                    <Route path="/*" element={<Typography>Hello World!</Typography>}/>
                </Routes>
                :
                <Routes>
                    <Route path="/auth/logout" element={<Logout/>}/>
                </Routes>
        }
    </>
})

export default ViewPort;